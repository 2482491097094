var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CDataTable',{staticClass:"table-add-couriers",attrs:{"striped":"","hover":"","items":_vm.couriers,"fields":_vm.fields,"loading":_vm.loading,"table-filter":{
    label: 'Search:',
    placeholder: 'Search for couriers...',
    external: false,
    lazy: false
  },"noItemsView":{
    noResults: 'No filtering results are available!',
    noItems: 'No couriers found!',
  },"clickable-rows":""},on:{"row-clicked":_vm.rowClicked},scopedSlots:_vm._u([{key:"select",fn:function({item}){return [_c('td',{staticClass:"no-print"},[_c('CInputCheckbox',{attrs:{"checked":item._selected,"custom":""},on:{"update:checked":() => _vm.check(item)}})],1)]}},{key:"id",fn:function({item}){return [_c('td',[_vm._v(" #"+_vm._s(item?.id)+" ")])]}},{key:"image_icon",fn:function({item}){return [_c('td',[_c('CImg',{staticClass:"c-avatar-img",staticStyle:{"width":"36px","height":"36px"},attrs:{"src":item.image_icon || item.gravatar,"placeholderColor":"lightgray"}})],1)]}},{key:"first_name",fn:function({item}){return [_c('td',[_vm._v(" "+_vm._s(item.first_name)+" ")])]}},{key:"email",fn:function({item}){return [_c('td',[_c('div',[_c('CIcon',{staticClass:"mr-1",attrs:{"name":"cil-at"}}),_vm._v(_vm._s(item.email))],1),_c('div',[_c('CIcon',{staticClass:"mr-1",attrs:{"name":"cil-phone"}}),_vm._v(_vm._s(item.mobile))],1)])]}},{key:"vehicle",fn:function({item}){return [_c('td',[(item.vehicle)?_c('div',{staticClass:"d-flex align-items-center flex-gap-2"},[_c('MIcon',{attrs:{"tag":item.vehicle.mode}}),_c('span',{staticClass:"text-uppercase"},[_vm._v(_vm._s(item.vehicle.license_plate))])],1):_vm._e()])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }