<template>
  <CModal
    title="Select Courier Backup Priority:"
    color="white"
    :show.sync="active"
    :closeOnBackdrop="false"
  >
    <div class="vertical-center justify-content-center">
      <div class="radio-buttons">
        <label :class="{ 'active': selectedPriority == null }">
          <input type="radio" :value="null" v-model="selectedPriority" />
          <span class="icon">🏅</span>
        </label>
        <label :class="{ 'active': selectedPriority == 1 }">
          <input type="radio" value="1" v-model="selectedPriority" />
          <span class="icon">🥇</span>
        </label>
        <label :class="{ 'active': selectedPriority == 2 }">
          <input type="radio" value="2" v-model="selectedPriority" />
          <span class="icon">🥈</span>
        </label>
        <label :class="{ 'active': selectedPriority == 3 }">
          <input type="radio" value="3" v-model="selectedPriority" />
          <span class="icon">🥉</span>
        </label>
      </div>
    </div>

    <template #footer class="p-1">
      <CButton color="link" @click="active = false">Close</CButton>
      <CButton color="primary" :disabled="submitted" @click="updatePriority">
        <CSpinner v-if="submitted" size="sm" /> Update
      </CButton>
    </template>
  </CModal>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    courier: Object,
  },
  data() {
    return {
      selectedPriority: null,
      submitted: false,
    };
  },
  computed: {
    ...mapGetters(["errors"]),
    active: {
      get: function () { return this.$store.state.updateCourierPriorityModalShow },
      set: function (newValue) { this.$store.state.updateCourierPriorityModalShow = newValue }
    },
  },
  watch: {
    active: async function (val) {
      if (val) {
        this.selectedPriority = this.courier.pivot?.backup_priority;
      }
    },
  },
  methods: {
    async updatePriority() {
      const hubId = this.courier.pivot.hub_id;

      this.submitted = true;
      await this.$axios.patch(this.$backend.COURIER.HUBS.UPDATE_COURIER_BACKUP_PRIORITY.replace("{id}", hubId), {
        courier_id: this.courier.id,
        backup_priority: this.selectedPriority,
      })
        .then(() => {
          this.$emit('update-priority', { id: this.courier.id, backup_priority: this.selectedPriority });
          this.submitted = false;
        })
        .catch((error) => {
          this.submitted = false;
          this.$toast.error(typeof this.errors === 'object'
            ? Object.values(this.errors).join()
            : this.errors
          );
        })
    },
  },
};
</script>

<style scoped>
.radio-buttons {
  display: flex;
  gap: 1rem;
}

.radio-buttons label {
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s, border 0.2s;
  border: 2px solid transparent;
  border-radius: 5px;
}

.radio-buttons input[type="radio"] {
  display: none;
}

.radio-buttons .icon {
  font-size: 2rem;
}

.radio-buttons label.active {
  transform: scale(1.2);
  color: #007bff;
  border: 2px solid #007bff;
  box-shadow: 0 0 10px rgba(0, 123, 255, 0.5);
}
</style>
