<template>
  <div>
    <CCard>
      <CCardBody class="p-3">
        <div class="row">
          <!-- Country Filter -->
          <div class="col-lg-4 mb-2">
            <v-select
              class="v-select-filter"
              placeholder="Country.."
              :value="country"
              :options="authCountries"
              :reduce="c => c.value"
              :searchable="false"
              @input="countryFilter"
            />
          </div>

          <div class="col-lg mb-2">
            <v-select
              class="v-select-filter"
              placeholder="Please select a region"
              label="zcCity"
              v-model="selected_zip_code"
              :options="allZipCodes"
              :reduce="zc => ({id: zc.id, zcZip: zc.zcZip, zcCity: zc.zcCity})"
              :filter="filteredZipCodes"
              :loading="loading"
              @input="selectingZipCodes"
            >
              <template v-slot:option="zip_code">
                <strong>{{ zip_code.zcZip }}</strong> -
                <span>{{ zip_code.zcCity }} </span>
              </template>
              <span slot="no-options">Sorry, no matching options.</span>
            </v-select>
          </div>

          <div class="col-lg-12 mb-2 mb-lg-0">
            <div class="d-flex">
              <CButton
                size="sm"
                color="link"
                class="p-0 mr-2"
                @click="getZipCodesNearBy"
              >
                <CIcon name="cil-plus" /> Add delivery zones within {{maxDistanceNearBy}} km
              </CButton>

              <CInput
                class="m-0"
                type="range"
                :value.sync="maxDistanceNearBy"
                min="1" max="20" step="1"
              />
            </div>
          </div>
        </div>
      </CCardBody>
    </CCard>

    <CDataTable
      class="table-sm table-scopes"
      :items="hubScopes"
      :fields="fields"
      :noItemsView="{
        noResults: 'No filtering results are available!',
        noItems: 'No scopes found!',
      }"
    >
      <!-- Distance -->
      <template #distance="{ item }">
        <td class="align-middle">
          <CIcon v-if="item._classes === 'table-warning'" name="cil-warning" size="lg" />
          {{ item.distance | FixedNumber }}
        </td>
      </template>

      <!-- Country -->
      <template #country="{ item }">
        <td class="align-middle">
          <CIcon class="mr-1" :content="$options.flagSet[item.country.flag]" size="lg" />
          {{ item.country.label }}
        </td>
      </template>

      <!-- Zone -->
      <template #zone="{ item }">
        <td class="align-middle">
          <strong>{{ item.zcZip }}</strong>
          <small class="ml-1">{{ item.zcCity }}</small>
        </td>
      </template>

      <!-- Actions -->
      <template #actions="{ item, index }">
        <td class="align-middle">
          <CButton
            size="sm"
            color="danger"
            variant="ghost"
            shape="pill"
            @click="deleteItem(item, index)"
            :disabled="item.zcZip == zip && item.zcCountryId == country_id"
            ><CIcon name="cil-trash"
          /></CButton>
        </td>
      </template>
    </CDataTable>

    <CElementCover :opacity="0.4" v-show="submitted"/>
  </div>
</template>

<script>
import { flagSet } from '@coreui/icons'
import _debounce from 'lodash/debounce';

export default {
  name: "Scopes",
  flagSet,
  props: {
    active: { default: false, type: Boolean },
    scopes: { default: () => [], type: Array },
    zip: { default: null, type: String },
    lat: { default: null, type: Number },
    lng: { default: null, type: Number },
    country_id: { default: 1, type: Number },
  },
  data() {
    return {
      allZipCodes: [],
      selected_zip_code: null,
      loading: false,
      submitted: false,
      maxDistanceNearBy: 10,

      fields: [
        { key: "distance",  label: "Distance (km)", filter: false, _style: "min-width: 80px; width: 80px;" },
        { key: "country",   label: "Country",       filter: false, _style: "min-width: 120px; width: 120px;" },
        { key: "zone",      label: "Delivery Zone", filter: false, _style: "min-width: 200px" },
        { key: "actions",   label: "",              filter: false, sorter: false, _style: "min-width: 48px; width: 48px;" },
      ],
    };
  },

  watch: {
    active: async function (val) {
      if (val) {
        await Promise.all([this.getAllZipCodes()]);
      }
    },

    country_id: [{
      handler: 'countryFilter'
    }],

    zip: async function (val) {
      await Promise.all([this.changedHubZip(val)]);
    },
  },

  computed: {
    hubScopes: {
      get() {
        return this.scopes.map((el) => {
          return {
            ...el,
            country: this.authCountries.find((c) => c.value === el.zcCountryId),
            distance: this.$globalFunc.haversineDistance(
              { lat: this.lat, lng: this.lng },
              { lat: el.zcLat, lng: el.zcLng }
            ),
          };
        })
        .map((el) => {
          return {
            ...el,
            _classes: el.distance > this.maxDistance ? "table-warning" : "",
          };
        });
      },
      set(newValue) {
        this.$emit("update:scopes", newValue);
      },
    },
    country() {
      return this.$store.state.filters.country || this.$store.state.countryId
    },
    maxDistance() {
      return process.env.VUE_APP_DELIVERY_ZONE_DISTANCE_WARNING_LIMIT;
    }
  },

  async beforeMount() {
    this.changedHubZip = _debounce(async (zip_code) => {
      if (this.country_id != this.country || !this.allZipCodes.length) {
        await Promise.all([this.countryFilter(this.country_id)]);
      }
      await Promise.all([this.addZipCodes(zip_code)]);
    }, 1000);

    await Promise.all([
      this.changedHubZip(this.zip)
    ]);
  },

  methods: {
    async getAllZipCodes() {
      this.allZipCodes = JSON.parse(sessionStorage.getItem(`${this.country}-zip-codes`)) || [];
      if (this.allZipCodes.length) {
        return;
      }

      this.loading = true;
      await this.$axios
        .get(this.$backend.ZIP_CODES.GET_ALL, {
          params: {
            country: this.country,
          },
        })
        .then((response) => (this.allZipCodes = response.data))
        .finally(() => {
          this.loading = false;
          sessionStorage.setItem(`${this.country}-zip-codes`, JSON.stringify(this.allZipCodes));
        });
    },

    deleteItem(item, index) {
      this.hubScopes = this.removeScopes(this.hubScopes, item.zcZip, item.zcCountryId);
    },

    removeScopes(scopes, zip, country) {
      return scopes.filter(function (item) {
        return (item.zcZip != zip) || (item.zcCountryId != country);
      });
    },

    filteredZipCodes(options, search) {
      return search.length
        ? options.filter(
            (option) =>
              option.zcZip.includes(search) ||
              option.zcCity.toLowerCase().includes(search.toLowerCase())
          )
        : null;
    },

    selectingZipCodes() {
      if (this.selected_zip_code) {
        this.addZipCodes(this.selected_zip_code.zcZip)
      }
    },

    addZipCodes(zip_code, zip_id) {
      let newZipCodes = [];

      const filteredZipCodes = zip_id
        ? this.allZipCodes.filter((f_zc) => f_zc.id == zip_id)
        : this.allZipCodes.filter((f_zc) => f_zc.zcZip == zip_code);

      filteredZipCodes.forEach((fe_zc) => {
        if (!this.hubScopes.some((i_zc) => i_zc.id == fe_zc.id)) {
          const item = {
            id: fe_zc.id,
            zcCity: fe_zc.zcCity,
            zcLat: fe_zc.zcLat,
            zcLng: fe_zc.zcLng,
            zcZip: fe_zc.zcZip,
            zcCountryId: fe_zc.zcCountryId,
            bounds: fe_zc.bounds
          };

          newZipCodes.push(item);
        }
      });

      this.hubScopes = this.hubScopes.concat(newZipCodes);
    },

    async getZipCodesNearBy() {
      const vm = this;
      this.submitted = true;

      await this.$axios
        .get(this.$backend.ZIP_CODES.NEAR_BY, {
          params: {
            "restaurant-latitude": this.lat,
            "restaurant-longitude": this.lng,
            "max-distance": this.maxDistanceNearBy,
          },
        })
        .then((response) => {
          response.data.forEach((zone, index) => {
            setTimeout(() => {
              vm.addZipCodes(zone.zcZip, zone.id);
              // scroll down one page
              window.scrollBy(0, window.innerHeight);
            }, index * 50);
          });
        })
        .finally(() => { this.submitted = false; });
    },

    // Filters
    async countryFilter(value) {
      this.$store.commit("setCountryFilter", value);
      this.selected_zip_code = null;
      await this.getAllZipCodes();
    },
  },
};
</script>

<style>
.table-scopes th {
  border-top: none;
}
</style>
