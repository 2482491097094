<template>
  <CDataTable
    class="table-add-couriers"
    striped
    hover
    :items="couriers"
    :fields="fields"
    :loading="loading"
    :table-filter="{
      label: 'Search:',
      placeholder: 'Search for couriers...',
      external: false,
      lazy: false
    }"
    :noItemsView="{
      noResults: 'No filtering results are available!',
      noItems: 'No couriers found!',
    }"
    clickable-rows
    @row-clicked="rowClicked"
  >
    <!-- Check Box -->
    <template #select="{item}">
      <td class="no-print">
        <CInputCheckbox
          :checked="item._selected"
          @update:checked="() => check(item)"
          custom
        />
      </td>
    </template>

    <!-- ID -->
    <template #id="{item}">
      <td>
        #{{ item?.id }}
      </td>
    </template>

    <!-- User Icon -->
    <template #image_icon="{item}">
      <td>
        <CImg
          :src="item.image_icon || item.gravatar"
          class="c-avatar-img"
          style="width: 36px; height: 36px"
          placeholderColor="lightgray"
        />
      </td>
    </template>

    <!-- Name -->
    <template #first_name="{item}">
      <td>
        {{ item.first_name }}
      </td>
    </template>

    <!-- Contact -->
    <template #email="{item}">
      <td>
        <div><CIcon name="cil-at" class="mr-1" />{{ item.email }}</div>
        <div><CIcon name="cil-phone" class="mr-1" />{{ item.mobile }}</div>
      </td>
    </template>

    <!-- Vehicle -->
    <template #vehicle="{item}">
      <td>
        <div v-if="item.vehicle" class="d-flex align-items-center flex-gap-2">
          <MIcon :tag="item.vehicle.mode" />
          <span class="text-uppercase">{{ item.vehicle.license_plate }}</span>
        </div>
      </td>
    </template>
  </CDataTable>
</template>

<script>
export default {
  name: "AddCouriers",
  props: {
    active: { default: false, type: Boolean },
    courierIds: { type: String, default: null },
    selectedItems: { default: () => [], type: Array },
    options: {
      type: Object,
      default: () => ({
        excludeSubordinates: false,
        excludeDispatchers: false
      })
    }
  },
  data() {
    return {
      fields: [
        { key: "select", label: "", filter: false, sorter: false, _classes:"no-print", _style: "width: 1%" },
        { key: "id", label: "ID", _style: "min-width: 60px; width: 60px;" },
        { key: "image_icon", label: "", sorter: false,  _style: "min-width: 60px; width: 60px;" },
        { key: "first_name", _style: "min-width: 140px;" },
        { key: "email", _style : "min-width: 200px;" },
        { key: "vehicle", _style : "min-width: 200px;" },
      ],

      couriers: [],
      loading: true,
    };
  },

  computed: {
    mergedOptions() {
      return {
        excludeSubordinates: false,
        excludeDispatchers: false,
        ...this.options
      };
    }
  },

  watch: {
    active: async function (val) {
      if (val) {
        this.couriers = this.couriers.map(({ _classes, _selected, ...rest }) => rest);
        await Promise.all([this.getCouriers()]);
        this.loading = false;
      }
    },
  },

  methods: {
    async getCouriers() {
      await this.$axios
        .get(this.$backend.COURIER.COURIERS.GET_LIST, {
          params: {
            'except-ids': this.courierIds,
            'exclude-dispatchers': this.mergedOptions.excludeDispatchers,
            'exclude-subordinates': this.mergedOptions.excludeSubordinates,
          }
        })
        .then(({ data }) => {
          this.couriers = data.map((el) => {
            return {
              ...el,
              _classes: '',
            }
          });
        })
        .catch(() => this.couriers = [])
    },

    rowClicked(item, index, column, e) {
      // INPUT, LABEL ve BUTTON dışında herhangi bir satır nesnesine tıklanırsa;
      if (!['INPUT', 'LABEL', 'BUTTON', 'A'].includes(e.target.tagName)) {
        this.check(item)
      }
    },
    check(item) {
      this.$set(item, "_selected", !item._selected);

      if (item._selected) {
        item._classes = item._classes.concat(" table-selected");
        this.selectedItems.push(item);
      } else {
        item._classes = item._classes.replace(" table-selected", "");
        this.selectedItems.splice(this.selectedItems.indexOf(item), 1);
      }
    },
  },
};
</script>

<style>
.table-add-couriers table {
  margin-bottom: 0;
}
.table-add-couriers th,
.table-add-couriers td {
  padding: 0.5rem 0.5rem;
  vertical-align: middle;
}
.table-add-couriers th {
  border-top: none;
}

/* Table filter */
.table-add-couriers label.mfe-2 {
  display: none;
}
.table-add-couriers label.mfe-2 + input {
  width: 100% !important;
  font-size: 1rem;
  font-weight: 500;
  color: #f9b115 !important;
}
</style>
